import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Drawer, Grid, IconButton, MenuItem, Skeleton, Slide, TextareaAutosize, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { LoadingButton } from '@mui/lab';
import { urlapi } from '../../lib/backend/data';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import { Title } from '@mui/icons-material';
import StarterKit from "@tiptap/starter-kit";
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  RichTextEditorProvider,
  RichTextField,
} from "mui-tiptap";
import { useEditor } from '@tiptap/react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


const EditarRegistroDrawer = (props) => {
    const {
        data,
        val
    } = props
    const editor = useEditor({
        extensions: [StarterKit],
        content: "<p>Hello <b>world</b>!</p>",
      });    
    const [ error, setError ] = useState("")
    const [ loading, setLoading ] = useState(true)
    const dispatch = useDispatch()
    const [ loadingCambios, setLoadingCambios ] = useState(false)
    const [ campo, setCampo ] = useState(false)
    const [ showModal, setShowModal] = useState(false)
    const sesion = useSelector(state => state.miusuario)

    const handleChange = (e) => {
        console.log(e)
    }

    const consultarDatos = () => {
        obtenerDatos()
        setShowModal(true)
    }
    const handleModalRF = () => {
        return setShowModal(false)
    }

    const obtenerDatos = async () => {
        setLoading(true)
        return fetch(`${urlapi}/blog?id=${data?._id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                setError('Sin datos')
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(res._id){
                setCampo(res)
                editor?.commands.setContent(res?.content)
            }
            return setLoading(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const guardarCambios = async () => {
        const payload = { ...campo, content: editor.getHTML() }
        setLoadingCambios(true)
        return fetch(`${urlapi}/blog`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                setError('Sin datos')
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(res._id){
                
            }
            return setLoadingCambios(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoadingCambios(false)
        })
    }

    const formulario = () => {
        if(loading) return <div>
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
        </div>

        if(!campo) return <Title>No se pudo encontrar el registro</Title>

        return <div>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Detalles</Typography>
                    <TextField 
                        sx={{ mb: 2 }} 
                        id="outlined-basic" 
                        label="Título" 
                        name="titulo" 
                        onChange={handleChange} 
                        value={campo.titulo} 
                        variant="outlined" fullWidth 
                    />
                    
                    <div style={{ marginBottom: 20 }}>
                    <RichTextEditorProvider editor={editor}>
                        <RichTextField
                            controls={
                            <MenuControlsContainer>
                                <MenuSelectHeading />
                                <MenuDivider />
                                <MenuButtonBold />
                                <MenuButtonItalic />
                                {/* Add more controls of your choosing here */}
                            </MenuControlsContainer>
                            }
                        />
                        </RichTextEditorProvider>
                    </div>
                    <LoadingButton loading={loadingCambios} variant="contained" color="success" fullWidth onClick={()=>guardarCambios()} >GUARDAR</LoadingButton>
                </Grid>
            </Grid>
        </div>
    }

    return <div>
        <Button onClick={() => consultarDatos()}>{val}</Button>
        <Dialog
            open={showModal}
            fullWidth={true}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            onClose={()=>handleModalRF()}
            aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle id="alert-dialog-slide-description">{data?.titulo}</DialogTitle>
        <DialogContent>
            {formulario()}
        </DialogContent>
        </Dialog>
    </div>
}

export default EditarRegistroDrawer

