import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Drawer, Grid, IconButton, MenuItem, Skeleton, Slide, TextareaAutosize, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { LoadingButton } from '@mui/lab';
import { urlapi } from '../../lib/backend/data';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import { Title } from '@mui/icons-material';
import StarterKit from "@tiptap/starter-kit";
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  RichTextEditorProvider,
  RichTextField,
} from "mui-tiptap";
import { useEditor } from '@tiptap/react';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import SelectorGeneral from '../../subcomponents/general/selector_general';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const CrearRegistroDialog = (props) => {
    const {
        data,
        val
    } = props
    const editor = useEditor({
        extensions: [StarterKit],
        content: "<p>Escribe tu información aquí</b>!</p>",
      });    
    const [ error, setError ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const dispatch = useDispatch()
    const [ loadingCambios, setLoadingCambios ] = useState(false)
    const [ campo, setCampo ] = useState({})
    const [ showModal, setShowModal] = useState(false)
    const sesion = useSelector(state => state.miusuario)

    const handleChangeSelect = (name, data) => {
        if(!data?._id) return  setCampo(prev => {
            const actual = { ...prev}
            delete actual[name]
            return actual
        })
        const value = data?._id
        return setCampo(prev => {
            const actual = { ...prev}
            actual[name] = value
            return actual
        })
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            const actual = { ...prev}
            actual[name] = value
            return actual
        })
    }
    const consultarDatos = () => {
        setShowModal(true)
    }
    const handleModalRF = () => {
        return setShowModal(false)
    }
    const guardarCambios = async () => {
        setError("")
        const payload = { ...campo, content: editor.getHTML() }
        setLoadingCambios(true)
        return fetch(`${urlapi}/blog`,{
            method:'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                setError('Sin datos')
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(res._id){
                setShowModal(false)
                setCampo({})
                if(props.onCreated) props.onCreated()
            }
            return setLoadingCambios(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoadingCambios(false)
        })
    }

    const formulario = () => {
        if(loading) return <div>
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
        </div>

        if(!campo) return <Title>No se pudo encontrar el registro</Title>

        return <div>
                <Typography variant="h6" sx={{ mb: 2 }}>Detalles</Typography>
            <Grid container spacing={3}>
                <Grid item md={8}>
                    <TextField 
                        sx={{ mb: 2 }} 
                        id="outlined-basic" 
                        label="Título" 
                        name="titulo" 
                        onChange={handleChange} 
                        value={campo.titulo} 
                        variant="outlined" fullWidth 
                    />
                </Grid>
                <Grid item md={4}>
                <SelectorGeneral value={campo.id_categoria} otherProps={{ label: "Categoría" }} strict={true} hideLabel={true} module="categorias" condicion_default={{ tipo: "blog" }} onChange={(e) => handleChangeSelect("id_categoria", e)} />
                </Grid>
                <Grid item md={12}>
                    
                    
                    <div style={{ marginBottom: 20 }}>
                    <RichTextEditorProvider editor={editor}>
                        <RichTextField
                            controls={
                            <MenuControlsContainer>
                                <MenuSelectHeading />
                                <MenuDivider />
                                <MenuButtonBold />
                                <MenuButtonItalic />
                                {/* Add more controls of your choosing here */}
                            </MenuControlsContainer>
                            }
                        />
                        </RichTextEditorProvider>
                    </div>
                    {error ? <Alert sx={{ mb: 2 }} variant="outlined" severity="error" >{error}</Alert> : false }
                    <LoadingButton loading={loadingCambios} variant="contained" color="success" fullWidth onClick={()=>guardarCambios()} >GUARDAR</LoadingButton>
                </Grid>
            </Grid>
        </div>
    }

    return <div>
        <Button startIcon={<AiOutlinePlusSquare size={15} /> } variant='outlined' onClick={() => consultarDatos()}>CREAR</Button>
        <Dialog
            open={showModal}
            fullWidth={true}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            onClose={()=>handleModalRF()}
            aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle id="alert-dialog-slide-description">{data?.titulo}</DialogTitle>
        <DialogContent>
            {formulario()}
        </DialogContent>
        </Dialog>
    </div>
}

export default CrearRegistroDialog